import * as React from "react";
import { Anchor, Button, Heading, Paragraph } from "grommet";
import Outsourcing from "../../../images/outsourcing-1.png";

const OutsourcingPage = () => {
  return (
    <>
      <Heading level={2} style={{color: "#3600b2"}}>Outsourcing</Heading>
      <Paragraph fill>
      <strong style={{color:"#3600b2"}}>Encontrar profissionais</strong> especializados e capacitados na área de TI é uma <strong style={{color:"#3600b2"}}>tarefa extremamente complexa e custosa,</strong> quando buscamos profissionais com <strong style={{color:"#3600b2"}}>especialização em dados e transformação digital</strong> (arquitetos de dados, engenheiros de dados, cientistas de dados, especialistas em inteligência artificial, especialistas em governança de dados, DPOs, desenvolvedores frontend e/ou backend, entre outros), essa <strong style={{color:"#3600b2"}}>complexidade aumenta exponencialmente</strong>.
      </Paragraph>
      <Paragraph fill>
        Ofertamos aos clientes a possibilidade de <strong style={{color:"#3600b2"}}>transferir</strong> toda a <strong style={{color:"#3600b2"}}>complexidade</strong> de implementação de <strong style={{color:"#3600b2"}}>projetos de data</strong> para o <strong style={{color:"#3600b2"}}>nosso centro de desenvolvimento</strong>, onde possuímos profissionais de diversos perfis aptos a implementarem as soluções de nossos clientes de <strong style={{color:"#3600b2"}}>forma ágil e assertiva</strong>, orquestrando dados, ferramentas, frameworks, códigos e ambientes do início ao fim. 
      </Paragraph>
      <Paragraph fill>
      Através de um <strong style={{color:"#3600b2"}}>atendimento exclusivo e personalizado</strong> baseado no <strong style={{color:"#3600b2"}}>modelo agile</strong>, para atender às necessidades de cada cliente, com células dedicadas, disponibilizando consultores especialistas, que atuarão de maneira consultiva e com <strong style={{color:"#3600b2"}}>foco total no resultado</strong>.
      </Paragraph>
      <img src={Outsourcing} alt="Outsourcing" />
      <Paragraph fill>
        <strong style={{color:"#3600b2"}}>Benefícios:</strong>
        <ul style={{marginBottom: "5px"}}>
          <li><strong style={{color:"#3600b2"}}>Mão de obra qualificada</strong> e disponível;</li>
          <li>Projetos visando <strong style={{color:"#3600b2"}}>produtização e automação</strong>;</li>
          <li>Gestão de <strong style={{color:"#3600b2"}}>melhoria contínua</strong>;</li>
          <li>Execução <strong style={{color:"#3600b2"}}>remoto, híbrido ou presencial;</strong></li>
        </ul>
      </Paragraph>
      <Paragraph fill>
        <strong style={{color:"#3600b2"}}>Gestão de Conhecimento:</strong>
        <ul style={{marginBottom: "5px"}}>
          <li>Através de métodos proprietários garantimos que <strong style={{color:"#3600b2"}}>o conhecimento seja mantido e, caso necessário, transferido</strong> de maneira simples para nossos <strong style={{color:"#3600b2"}}>clientes</strong>.</li>
          <li>Nossos projetos são pensados para que haja a <strong style={{color:"#3600b2"}}>maximização do tempo e reaproveitamento de componentes</strong> dos desenvolvimentos realizados.</li>
        </ul>
      </Paragraph>
      <Paragraph fill>
        <strong style={{color:"#3600b2"}}>Gestão Ágil:</strong>
        <ul style={{marginBottom: "5px"}}>
          <li><strong style={{color:"#3600b2"}}>Mão de obra qualificada</strong> e disponível;</li>
          <li>Projetos visando <strong style={{color:"#3600b2"}}>produtização e automação</strong>;</li>
          <li>Gestão de <strong style={{color:"#3600b2"}}>melhoria contínua</strong>;</li>
          <li>Nossos projetos são acompanhados através de <strong style={{color:"#3600b2"}}>métodos ágeis adaptados para as realidades encontradas</strong> em projetos de <strong style={{color:"#3600b2"}}>Data</strong> dando total <strong style={{color:"#3600b2"}}>visibilidade do início ao fim aos nossos clientes.</strong></li>
        </ul>
      </Paragraph>
      <Paragraph fill style={{textAlign: "center"}}>
        <a href="/contact"><Button type="submit" label="SAIBA MAIS" primary size="small" style={{width: "250px"}} /></a>{" "}
        <a href="/contact"><Button type="submit" label="CONTRATAR" primary size="small" style={{width: "250px"}}/></a>
      </Paragraph>
    </>
  );
};

export default OutsourcingPage;
